import autocreate from 'autocreate.js';
import {easeInOutQuad} from 'easings.js';
import scrrroll from 'scrrroll';
import app from '@/modules/app';
import {isMobile} from '@/utils/features';

scrrroll.duration = 1000;
scrrroll.easing = easeInOutQuad;
let headerHeight = 0;
const updateHeaderHeight = () => {
    headerHeight = document.querySelector('.header').clientHeight;
    scrrroll.offset = headerHeight;
};
window.addEventListener('resize', updateHeaderHeight);
window.addEventListener('load', updateHeaderHeight);

app.on('init', () => {
    autocreate({
        selector: '[data-scroll-spy]',
        create: function (dom) {
            const links = Array.from(dom.querySelectorAll('a[href^="#"]'));
            const targets = [];

            let offsetMobileElement;
            let offsetMobile = 0;
            if (dom.dataset.offsetMobile) {
                offsetMobileElement = document.querySelector(dom.dataset.offsetMobile);
                if (offsetMobileElement) {
                    const updateOffsetMobile = () => {
                        if (isMobile()) {
                            offsetMobile = offsetMobileElement.clientHeight;
                        } else {
                            offsetMobile = 0;
                        }
                    };
                    window.addEventListener('resize', updateOffsetMobile);
                    updateOffsetMobile();
                }
            }

            let currentTarget;

            links.forEach(link => {
                const target = document.querySelector(link.getAttribute('href'));

                if (target) {
                    targets.push(target);
                }

                link.addEventListener('click', e => {
                    e.preventDefault();

                    if (target) {
                        const top = target.getBoundingClientRect().top;
                        const scroll = window.pageYOffset;
                        const destination = Math.ceil(top + scroll - (headerHeight + offsetMobile));

                        scrrroll.to(destination);
                    }
                });
            });

            const update = () => {
                const current = [...targets].reverse().find(target => window.pageYOffset >= target.offsetTop - (headerHeight + offsetMobile + 20));

                if (current && current !== currentTarget) {
                    const currentLink = links.find(link => {

                        return link.getAttribute('href') === `#${current.id}`;
                    });

                    links.forEach(link => link.classList.remove('active'));
                    currentLink.classList.add('active');

                    if (isMobile()) {
                        currentLink.closest('ul').scrollTo({top: 0, left: currentLink.closest('li').offsetLeft, behavior: 'smooth'})

                    }
                } else if (!current) {
                    links.forEach(link => link.classList.remove('active'));
                }
                currentTarget = current;
            };

            // todo: needs to show active element on page load/reload inc. top of page.
            window.addEventListener('scroll', update);
            window.addEventListener('load', update);
        },
    });
});
