import Glide from '@glidejs/glide';
import app from '@/modules/app';
import autocreate from "autocreate.js";

app.on('init', () => {

	autocreate({
		selector: '[data-glide]',
		create: function (dom) {
		let options = {
			type: 'carousel',
			perView: 4,
			gap: 0,
			keyboard: false,
			autoplay: 2000,
			breakpoints: {
                1400: {
					perView: 3,
				},
				940: {
					perView: 2,
				},
				600: {
					perView: 1,
				},
			},
			// swipeThreshold: 80,
			// dragThreshold: 120,
			perTouch: 1,
			touchRatio: 0.5,
			touchAngle: 45,
			rewind: true,
		};

			const glide = new Glide(dom, options).mount();

}
	});
});
