import app from '@/modules/app';
import '@/modules/scrollSpy';
import '@/modules/lax';
import '@/modules/navigation';
import '@/modules/scrollToTop';
import '@/modules/carousel';
import '@/modules/autoheight';
import '@/modules/scrollTo';
import '@/modules/video';


window.addEventListener('pageshow', e => {
	if (e.persisted) {
		window.location.reload();
	}
});

window.addEventListener('load', () => {
	app.loader.hide();
});

app.on('init', async () => {
	// request example
	const data = await app.get('https://req.dev.so');
	console.log(data);
});

document.addEventListener('DOMContentLoaded', async () => {
	await app.emit('init', null, true);
	await app.emit('init:page', null, true);
	app.initialized = true;
	if (document.readyState === 'complete') {
		app.loader.hide();
	}
	const event = document.createEvent('Event');
	event.initEvent('site-init', true, true);
	window.dispatchEvent(event);
});

window.addEventListener('site-init', () => {
	console.log('all done');
});
//
// if (hasServiceWorker) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/sw.js').then(registration => {
//       // Registration was successful
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }, error => {
//       // registration failed :(
//       console.log('ServiceWorker registration failed: ', error);
//     });
//   });
// }

if (process.env.NODE_ENV !== 'production') {
	window.app = app;
}
