import autocreate from 'autocreate.js';
import app from '@/modules/app';

app.on('init', () => {

	autocreate({
		selector: '.video-w-poster',
		create: function (el) {
			const poster = el.querySelector('.video__poster');
			const iframe = document.createElement('div');
			const tmpl = el.querySelector('script[type="text/template"]');
			const videoContainer = el.closest('.double-sided__side--w-video');

			if (!tmpl) {
				return false;
			}
			iframe.innerHTML = tmpl.innerHTML;
			tmpl.parentNode.removeChild(tmpl);

				poster.addEventListener('click', e => {
					e.preventDefault();

					if (!document.contains(iframe)) {
						poster.parentNode.replaceChild(iframe, poster);
					}


                    videoContainer.style.zIndex = "99"

				});

		},
	});
});
