export const breakpointOrder = [
  'xxs',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl'
];

export default breakpointOrder;
