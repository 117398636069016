import autocreate from 'autocreate.js';
import app from '@/modules/app';

app.on('init', () => {

	autocreate({
		selector: '.navigate-down',
		create: function(el) {
			const scrollTarget = document.documentElement.querySelector('#section-1');

			el.addEventListener('click', () => {
				scrollTarget.scrollIntoView({behavior: "smooth"});
			});
		},
	});
});
