import htmlComponent from 'htmlcomponent'
import app from '@/modules/app';
import {isMobile} from '@/utils/features';
import autocreate from "autocreate.js";

app.on('init', () => {

	autocreate({
		selector: '[data-nav-toggle]',
		create: function (dom) {

			let options = {
				hamburgerSel: '.hamburger',
				navSel: '.nav-wrapper',
				activeCls: 'mobile-active',
				bodyShadowCls: 'show-shadow',
				htmlNavOpenCls: 'navigation-open',
				openNavCls: 'expanded',
			};

			const hamburger = dom.querySelector(options.hamburgerSel);
			const navigation = document.querySelector(options.navSel);
			const html = document.documentElement;
			const body = document.body;

			function mobileNavigation() {
				hamburger.addEventListener('click', () => {
					// html class
					html.classList.toggle(options.htmlNavOpenCls);
					body.classList.toggle(options.bodyShadowCls);

					function toggleClass() {
						if (navigation.classList.contains(options.activeCls)) {
							navigation.classList.remove(options.activeCls, options.openNavCls);
						} else {
							navigation.classList.add(options.activeCls, options.openNavCls);
						}
					}
					toggleClass();
				})
			}

			if (isMobile) {
				mobileNavigation();
			}
		}
	});
});

