import lax from 'lax.js';
import app from '@/modules/app';


app.on('init', () => {
	lax.init();

	// Add a driver that we use to control our animations
	lax.addDriver('scrollY', function () {
		return window.scrollY
	});

	// Add animation bindings to elements
	lax.addElements('.animate-right-image', {
		scrollY: {
			translateY: [
				[ 'elInY', 'elCenterY', 'elOutY'],
				{
					1023: [ 0, 0],
					1024: [ 0, 50, 100]
				},
			],
			translateX: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [0, 0, 0],
					1024: [-15, -15, -15]
				},
			],
		}
	});

	lax.addElements('.animate-left-image', {
		scrollY: {
			translateY: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [0, 0],
					1024: [0, 100, 200],
					1440: [0, 50, 100]
				},
			],
			translateX: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [0, 0, 0],
					1024: [15, 15, 15]
				},
			],
		}
	});

	lax.addElements('.animate-left-text', {
		scrollY: {
			translateY: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [-30, -30, -30],
					1024: [0, -50, -50]
				},
			],
			translateX: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [0, 0, 0],
					1024: [15, 15, 15]
				},
			],
		}
	});

	lax.addElements('.animate-right-text', {
		scrollY: {
			translateY: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [-30, -30, -30],
          1024: [0, -50, -50],
				},
			],
			translateX: [
				['elInY', 'elCenterY', 'elOutY'],
				{
					1023: [0, 0, 0],
					1024: [-15, -15, -15]
				},
			],
		}
	});
});
