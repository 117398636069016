import app from '@/modules/app';
import autocreate from "autocreate.js";

app.on('init', () => {
	autocreate({
		selector: '[data-height]',
		create: function (dom) {
			let options = {
				textAreaSel: '.textarea',
			};

			const textArea = dom.querySelector(options.textAreaSel);

			textArea.addEventListener('keyup', () => {
				adjustHeight(textArea);
			});

			function adjustHeight(e) {
				e.style.height = "1px";
				e.style.height = (25 + e.scrollHeight)+"px";
			}
		}
	});
});



